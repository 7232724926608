.item {
    position: relative;
    padding: theme('spacing.075') 0;
    transition-property: height, opacity;
    transition-duration: theme('transitionDuration.fast');
    transition-timing-function: theme('transitionTimingFunction.easeIn');
}

.image {
    object-fit: contain;
    flex-shrink: 0;
    width: theme('icon.sizes.large');
}

.unavailableImage {
    display: flex;
    align-items: center;
    justify-content: center;
    height: theme('icon.sizes.large');
    width: theme('icon.sizes.large');
    border-radius: 100%;
    border: theme('card.bordered.border');

    svg {
        width: 50%;
        height: 50%;
    }
}
