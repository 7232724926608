.prescription {
    display: flex;
    flex-direction: column;
    gap: theme('spacing.rhythm-xsmall');

    @media (min-width: theme('screens.lg')) {
        gap: theme('spacing.rhythm-small');
    }
}

.notice {
    padding: theme('spacing.150') theme('spacing.100');
    border-top: 1px solid theme('colors.decorators.subtle');
}
