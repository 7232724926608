.OrderedListItem {
    counter-increment: item;
    display: flex;
    gap: theme('spacing.050');

    &::before {
        @apply type-heading leading-none text-090;

        content: counter(item);
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: theme('size.150');
        height: theme('size.150');
        border-radius: theme('borderRadius.round');
        background-color: theme('colors.content.foundation');
        color: theme('colors.layers.foreground');
        box-sizing: border-box;
    }
}
