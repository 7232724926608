.wrapper {
    padding: theme('spacing.050');
    border-radius: theme('borderRadius.200');
    background: theme('colors.layers.soft');
    padding: theme('spacing.050');
    color: theme('colors.layers.highlight');

    @media (min-width: theme('screens.md')) {
        padding: theme('spacing.100');
    }

    @media (min-width: theme('screens.lg')) {
        padding: theme('spacing.200');
    }
}

.container {
    padding: theme('spacing.300') theme('container.gutters.medium');
    border: 1px solid theme('colors.decorators.muted');
    border-radius: inherit;
}

.list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: theme('container.sizes.xsmall');
    margin: theme('rhythm.small') auto 0;
    gap: theme('spacing.050');

    @supports (width: fit-content) {
        width: fit-content;
    }

    @media (min-width: theme('screens.md')) {
        flex-direction: row;
        gap: 0;
        max-width: theme('container.sizes.medium');
        width: 100%;
    }
}

.listItem {
    display: flex;
    align-items: center;
    gap: theme('spacing.150');

    @media (min-width: theme('screens.md')) {
        flex-direction: column;
        gap: theme('spacing.100');
        text-align: center;
    }
}

.listArrow {
    @media (min-width: theme('screens.md')) {
        position: relative;
        width: theme('spacing.200');
        flex-shrink: 0;
    }

    img {
        @media (min-width: theme('screens.md')) {
            position: absolute;
            left: 50%;
            max-width: none;
        }
    }
}

.listArrowRegular {
    margin-left: 2rem;

    @media (min-width: theme('screens.md')) {
        margin: 0;
    }

    img {
        @media (min-width: theme('screens.md')) {
            transform: translateX(-50%);
        }
    }
}

.listArrowFlipped {
    img {
        transform: scaleX(-1);

        @media (min-width: theme('screens.md')) {
            transform: scale(1, -1) translate(-50%, -1.5rem);
        }
    }
}

.shield {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    padding: theme('spacing.075');
    box-sizing: content-box;
    border-radius: 2px 2px 40px 40px;
    background: theme('colors.neutrals.white');
    overflow: visible;

    @media (min-width: theme('screens.md')) {
        margin: 0 auto;
    }
}
